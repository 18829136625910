import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Label, StyledLabel } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Label" title="Label" mdxType="PageTitle" />
    <h2 {...{
      "id": "standard-label"
    }}>{`Standard Label`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Label strength=\"standard\">Super awesome label</Label>\n  <Label muted strength=\"standard\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"standard\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"standard\" validity=\"danger\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"standard\" validity=\"danger\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"standard\" validity=\"danger\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"standard\" validity=\"warning\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"standard\" validity=\"warning\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"standard\" validity=\"warning\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"standard\" validity=\"success\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"standard\" validity=\"success\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"standard\" validity=\"success\">\n    Super awesome disabled label\n  </Label>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Label,
      StyledLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Label strength="standard" mdxType="Label">Super awesome label</Label>
    <Label muted strength="standard" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="standard" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="standard" validity="danger" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="standard" validity="danger" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="standard" validity="danger" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="standard" validity="warning" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="standard" validity="warning" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="standard" validity="warning" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="standard" validity="success" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="standard" validity="success" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="standard" validity="success" mdxType="Label">
      Super awesome disabled label
    </Label>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitioned-label"
    }}>{`Transitioned Label`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Label strength=\"transitioned\">Super awesome label</Label>\n  <Label muted strength=\"transitioned\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"transitioned\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"transitioned\" validity=\"danger\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"transitioned\" validity=\"danger\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"transitioned\" validity=\"danger\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"transitioned\" validity=\"warning\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"transitioned\" validity=\"warning\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"transitioned\" validity=\"warning\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"transitioned\" validity=\"success\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"transitioned\" validity=\"success\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"transitioned\" validity=\"success\">\n    Super awesome disabled label\n  </Label>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Label,
      StyledLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Label strength="transitioned" mdxType="Label">Super awesome label</Label>
    <Label muted strength="transitioned" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="transitioned" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="transitioned" validity="danger" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="transitioned" validity="danger" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="transitioned" validity="danger" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="transitioned" validity="warning" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="transitioned" validity="warning" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="transitioned" validity="warning" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="transitioned" validity="success" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="transitioned" validity="success" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="transitioned" validity="success" mdxType="Label">
      Super awesome disabled label
    </Label>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "legend-label"
    }}>{`Legend Label`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Label strength=\"legend\">Super awesome label</Label>\n  <Label muted strength=\"legend\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"legend\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"legend\" validity=\"danger\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"legend\" validity=\"danger\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"legend\" validity=\"danger\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"legend\" validity=\"warning\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"legend\" validity=\"warning\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"legend\" validity=\"warning\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label strength=\"legend\" validity=\"success\">\n    Super awesome label\n  </Label>\n  <Label muted strength=\"legend\" validity=\"success\">\n    Super awesome muted label\n  </Label>\n  <Label disabled strength=\"legend\" validity=\"success\">\n    Super awesome disabled label\n  </Label>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Label,
      StyledLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Label strength="legend" mdxType="Label">Super awesome label</Label>
    <Label muted strength="legend" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="legend" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="legend" validity="danger" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="legend" validity="danger" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="legend" validity="danger" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="legend" validity="warning" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="legend" validity="warning" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="legend" validity="warning" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label strength="legend" validity="success" mdxType="Label">
      Super awesome label
    </Label>
    <Label muted strength="legend" validity="success" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label disabled strength="legend" validity="success" mdxType="Label">
      Super awesome disabled label
    </Label>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-label"
    }}>{`Contrast Label`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper contrast>\n  <Label contrast strength=\"standard\">\n    Super awesome label\n  </Label>\n  <Label contrast muted strength=\"standard\">\n    Super awesome muted label\n  </Label>\n  <Label contrast disabled strength=\"standard\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label contrast strength=\"standard\" validity=\"danger\">\n    Super awesome label\n  </Label>\n  <Label contrast muted strength=\"standard\" validity=\"danger\">\n    Super awesome muted label\n  </Label>\n  <Label contrast disabled strength=\"standard\" validity=\"danger\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label contrast strength=\"standard\" validity=\"warning\">\n    Super awesome label\n  </Label>\n  <Label contrast muted strength=\"standard\" validity=\"warning\">\n    Super awesome muted label\n  </Label>\n  <Label contrast disabled strength=\"standard\" validity=\"warning\">\n    Super awesome disabled label\n  </Label>\n  <br />\n  <Label contrast strength=\"standard\" validity=\"success\">\n    Super awesome label\n  </Label>\n  <Label contrast muted strength=\"standard\" validity=\"success\">\n    Super awesome muted label\n  </Label>\n  <Label contrast disabled strength=\"standard\" validity=\"success\">\n    Super awesome disabled label\n  </Label>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Label,
      StyledLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Label contrast strength="standard" mdxType="Label">
      Super awesome label
    </Label>
    <Label contrast muted strength="standard" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label contrast disabled strength="standard" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label contrast strength="standard" validity="danger" mdxType="Label">
      Super awesome label
    </Label>
    <Label contrast muted strength="standard" validity="danger" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label contrast disabled strength="standard" validity="danger" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label contrast strength="standard" validity="warning" mdxType="Label">
      Super awesome label
    </Label>
    <Label contrast muted strength="standard" validity="warning" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label contrast disabled strength="standard" validity="warning" mdxType="Label">
      Super awesome disabled label
    </Label>
    <br />
    <Label contrast strength="standard" validity="success" mdxType="Label">
      Super awesome label
    </Label>
    <Label contrast muted strength="standard" validity="success" mdxType="Label">
      Super awesome muted label
    </Label>
    <Label contrast disabled strength="standard" validity="success" mdxType="Label">
      Super awesome disabled label
    </Label>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "styled-label"
    }}>{`Styled Label`}</h2>
    <Playground __position={5} __code={'<StyledLabel mutedTextColor=\"#81BCFF\" strength=\"standard\" textColor=\"#0060ce\">\n  Super awesome label\n</StyledLabel>\n<StyledLabel\n  muted\n  mutedTextColor=\"#81BCFF\"\n  strength=\"standard\"\n  textColor=\"#0060ce\"\n>\n  Super awesome muted label\n</StyledLabel>\n<StyledLabel\n  disabled\n  mutedTextColor=\"#81BCFF\"\n  strength=\"standard\"\n  textColor=\"#0060ce\"\n>\n  Super awesome disabled label\n</StyledLabel>\n<br />\n<StyledLabel muted fontSize={20} lineHeight={24} unthemed={false}>\n  Super awesome giant label\n</StyledLabel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Label,
      StyledLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledLabel mutedTextColor="#81BCFF" strength="standard" textColor="#0060ce" mdxType="StyledLabel">
    Super awesome label
  </StyledLabel>
  <StyledLabel muted mutedTextColor="#81BCFF" strength="standard" textColor="#0060ce" mdxType="StyledLabel">
    Super awesome muted label
  </StyledLabel>
  <StyledLabel disabled mutedTextColor="#81BCFF" strength="standard" textColor="#0060ce" mdxType="StyledLabel">
    Super awesome disabled label
  </StyledLabel>
  <br />
  <StyledLabel muted fontSize={20} lineHeight={24} unthemed={false} mdxType="StyledLabel">
    Super awesome giant label
  </StyledLabel>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "label"
    }}>{`Label`}</h3>
    <Props of={Label} mdxType="Props" />
    <h3 {...{
      "id": "styledlabel"
    }}>{`StyledLabel`}</h3>
    <Props of={StyledLabel} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      